.StocksTable {
    width: 85%;
    height: 400px;
    border-radius: 20px;
    box-shadow: 0px 0px 10px rgb(213, 213, 213);
    padding: 20px 20px;
    display: flex;
    align-items: center;
}

table {
    width: 100%;
}

tbody tr td {
    text-align: center;
    padding: 6px;
}

thead tr th {
    padding: 10px 0; /* 위아래 10px 간격을 줌 */
}
