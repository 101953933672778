.swipeableContainer {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    padding: 20px;
    position: fixed;
    bottom: 73px;
    width: 100%;
    max-width: 440px;
    box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.1);
    transition: height 0.3s ease;
    background-color: #f7f7f7;
    overflow-y: auto;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
}

@media screen and (max-width: 768px) {
    .swipeableContainer {
        width: 90%;
        left: 47.5%;
    }
}

.swipeHandle {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.swipeHandle h3 {
    font-size: 16px;
}

.dragHandle {
    cursor: pointer;
}

.dragHandle img {
    width: 30px;
    height: 15px;
}

.circle-button {
    width: 37px;
    height: 37px;
    background-color: #615efc;
    border-radius: 50%;
    border: none;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.3);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: relative;
    margin-right: 30px;
}

.plus-icon {
    font-size: 35px;
    color: white;
    line-height: 1;
    margin-top: 2px;
}
