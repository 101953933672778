.embla {
  max-width: 48rem;
  margin: auto;
  --slide-height: 19rem;
  --slide-spacing: 0.1rem;
  --slide-size: 55%;
}

.embla__viewport {
  overflow: hidden;
}

.embla__container {
  display: flex;
  touch-action: pan-y pinch-zoom;
  margin-left: calc(var(--slide-spacing) * -1);
}

.embla__slide {
  transform: translate3d(0, 0, 0);
  flex: 0 0 var(--slide-size);
  min-width: 0;
  padding-left: var(--slide-spacing);
}

.embla__slide__number {
  border-radius: 20px;
  font-size: 18px;
  font-weight: 600;
  height: var(--slide-height);
  user-select: none;
  backface-visibility: hidden;
  background: linear-gradient(200deg, #615efc 0%, #d8d7ff 100%);
  color: white;
  padding: 2rem 2rem;
}

.embla__slide__info > h3 {
  font-size: 25px;
  margin-top: 0.7rem;
  margin-bottom: 1rem;
  min-height: 75px;
  word-wrap: break-word;
}

@media screen and (max-width: 768px) {
  .embla__slide__info > h3 {
    font-size: 20px;
    margin-top: 0.7rem;
    margin-bottom: 1rem;
    min-height: 40px;
    word-wrap: break-word;
  }
}

.embla__slide__info > p {
  margin: 0.2rem 0;
}

.img__container > img {
  width: 120px;
  height: 120px;
  margin-left: 2.5rem;
  margin-top: -2rem;
  position: absolute;
  top: 200px;
  right: 20px;
}

@media screen and (max-width: 768px) {
  .img__container > img {
    width: 80px;
    height: 80px;
    margin-left: 2rem;
    margin-top: 1rem;
    position: absolute;
    top: 190px;
    right: 20px;
  }
}

.embla__controls {
  display: flex;
  /* grid-template-columns: auto 1fr;
  justify-content: space-between; */
  gap: 1rem;
  margin-top: 1.8rem;
  flex-direction: column;
}

.embla__buttons {
  display: flex;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.6rem;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
}

.embla__button {
  appearance: none;
  touch-action: manipulation;
  cursor: pointer;
  border: 0;
  padding: 0;
  margin: 0;
  /* box-shadow: inset 0 0 0 0.2rem var(--detail-medium-contrast); */
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  color: rgb(137, 136, 136);
  display: flex;
  align-items: center;
  justify-content: center;
}

.embla__button:hover {
  background-color: #615efc;
  color: #fff;
}

.embla__button:disabled {
  color: var(--detail-high-contrast);
}

.embla__button__svg {
  width: 35%;
  height: 35%;
}

.embla__dots {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-right: calc((2.6rem - 1.4rem) / 2 * -1);
  gap: 1px;
}

.embla__dot {
  -webkit-tap-highlight-color: rgba(var(--text-high-contrast-rgb-value), 0.5);
  appearance: none;
  background-color: rgb(234, 234, 234);
  touch-action: manipulation;
  cursor: pointer;
  border: 0;
  padding: 0;
  margin: 0;
  width: 8px;
  height: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.embla__dot:after {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  content: "";
}

.embla__dot--selected:after {
  background-color: #615efc;
}
