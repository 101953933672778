.PfCard {
  width: 90%;
  height: 260px;
  border-radius: 20px;
  background-color: #615efc;
  padding: 37px 17px;
  box-shadow: 2px 5px 5px rgba(0, 0, 0, 0.35);
}

@media screen and (max-width: 768px) {
  .PfCard {
    width: 100%;
  }
}

.PfCard h3 {
  font-size: 20px;
  color: #e0e0e0;
  margin-bottom: 14px;
}

.PfCard p {
  font-size: 25px;
  color: #ffffff;
  font-weight: 700;
}

.detail-section {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 14px;
  margin-top: 50px;
  width: 100%;
}

.total-value,
.gain-and-loss {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.title-area {
  flex: 1;
  color: #cbcbcb;
  font-size: 15px;
}

.value-area {
  font-size: 18px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 5px;
}

.total-value .value-area {
  color: #ffffff;
}

.gain-and-loss .value-area {
  color: red;
}

.value-area > p {
  font-size: 19px;
}
