/* Variables converted to standard CSS */

.deleteContainer > button {
    position: relative;
    display: block;
    width: 25%;
    height: 100%;
    border: none;
    background: transparent;
    cursor: pointer;
    z-index: 99;
    outline: none;
    backface-visibility: hidden;
    -webkit-touch-callout: none;
}

.deleteContainer > button > div {
    position: absolute;
    top: 10px;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
}

/* Delete Button Styles */
.delete:hover .top {
    animation: delete 0.8s ease-out;
    background: #615efc;
}

.delete:hover .top::before {
    background: #615efc;
}

.delete:hover .bottom {
    background: #615efc;
}

.delete .top {
    position: absolute;
    top: 2px;
    width: 24px;
    height: 4px;
    background: rgb(88, 88, 88);
    z-index: 1;
    right: 20px;
}

.delete .top::before {
    content: "";
    position: absolute;
    top: -2px;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 14px;
    height: 6px;
    background: rgb(88, 88, 88);
    border-radius: 8px;
}

.delete .bottom {
    position: absolute;
    top: 8px;
    width: 24px;
    height: 26px;
    background: rgb(88, 88, 88);
    border-radius: 0 0 6px 6px;
    right: 20px;
}

/* Keyframes for Delete */
@keyframes delete {
    0% {
        transform: rotate(0) translateY(0);
    }
    20% {
        transform: rotate(0) translateY(-4px);
    }
    30% {
        transform: rotate(20deg) translateY(-4px);
    }
    40% {
        transform: rotate(-20deg) translateY(-4px);
    }
    50% {
        transform: rotate(20deg) translateY(-4px);
    }
    60% {
        transform: rotate(-10deg) translateY(-4px);
    }
    70% {
        transform: rotate(10deg) translateY(-4px);
    }
    80% {
        transform: rotate(0) translateY(-4px);
    }
    100% {
        transform: rotate(0) translateY(0);
    }
}
