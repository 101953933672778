.Portfolio {
  margin: 0 2rem;
}

.title {
  font-size: 25px;
  font-weight: 700;
  margin: 2rem 0;
  flex: 1;
}

.asset p {
  font-size: 14px;
  color: #bfbfbf;
  margin-bottom: 10px;
}

.asset-value {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.total-value {
  display: flex;
  align-items: center;
  justify-content: center;
  justify-content: space-between;
}

.total-growth {
  font-size: 12px;
  padding-left: 2rem;
}

.my-portfolio {
  margin-top: 100px;
}

.circle-button {
  width: 37px;
  height: 37px;
  background-color: #615efc;
  border-radius: 50%;
  border: none;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  margin-right: 30px;
}

.plus-icon {
  font-size: 35px;
  color: white;
  line-height: 1;
  margin-top: 2px;
}
