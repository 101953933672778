.MyStockItem {
    width: 100%;
}

.button-section {
    display: none;
    justify-content: flex-end;
    align-items: center;
    gap: 5px;
    margin-right: 10px;
    margin-bottom: 4px;
    margin-top: 10px;
}

.button-section button {
    cursor: pointer;
    border: none;
    background-color: transparent;
    /* color: #615EFC; */
    font-weight: 400;
    font-size: 12px;
    color: #6c757d;
}

.button-section button:hover {
    color: #615efc;
    font-weight: 700;
    font-size: 13px;
}

.MyStockItemWrapper {
    width: 100%;
    height: 70px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    /* border: 1px solid black; */
    background-color: white;
    border-radius: 8px;
    box-shadow: 3px 3px 3px rgb(213, 213, 213);
    padding: 0 10px;
    cursor: pointer;
    transition: transform 0.2s ease-in-out;
}

.StockItem:hover {
    background-color: #eaeaea;
    transform: scale(0.95);
}

.MyStockItem:hover .button-section {
    display: flex;
}

.logo {
    width: 40px;
    height: 40px;
    margin: 0 20px;
}

.info-section {
    flex: 1;
}

.info-section > h2 {
    font-size: 10px;
    font-weight: 700;
}

.info-section > p {
    font-size: 8px;
    font-weight: 400;
    color: #6c757d;
}

.price-section {
    font-size: 10px;
    font-weight: 400;
    /* flex: 1; */
    text-align: right;
    margin-right: 10px;
    width: 35%;
    display: flex;
    flex-direction: column;
    gap: 7px;
    font-weight: 100;
}

.price-section > div {
    font-size: 14px;
}

.growth-section {
    font-size: 8px;
    font-weight: 400;
    margin-right: 10px;
    width: 18%;
    text-align: right;
}
