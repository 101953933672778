@font-face {
    font-family: "SCDream1";
    font-style: normal;
    font-weight: 100;
    src: url("./SCDream1.otf") format("woff");
}

@font-face {
    font-family: "SCDream2";
    font-style: normal;
    font-weight: 200;
    src: url("./SCDream2.otf") format("woff");
}

@font-face {
    font-family: "SCDream3";
    font-style: normal;
    font-weight: 300;
    src: url("./SCDream5.otf") format("woff");
}

@font-face {
    font-family: "SCDream4";
    font-style: normal;
    font-weight: 400;
    src: url("./SCDream5.otf") format("woff");
}

@font-face {
    font-family: "SCDream5";
    font-style: normal;
    font-weight: 500;
    src: url("./SCDream5.otf") format("woff");
}

@font-face {
    font-family: "SCDream6";
    font-style: normal;
    font-weight: 600;
    src: url("./SCDream6.otf") format("woff");
}

@font-face {
    font-family: "SCDream7";
    font-style: normal;
    font-weight: 700;
    src: url("./SCDream7.otf") format("woff");
}

@font-face {
    font-family: "SCDream8";
    font-style: normal;
    font-weight: 800;
    src: url("./SCDream8.otf") format("woff");
}

@font-face {
    font-family: "SCDream9";
    font-style: normal;
    font-weight: 800;
    src: url("./SCDream9.otf") format("woff");
}